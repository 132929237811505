.switch {
  display: flex;
  align-items: center;
  min-width: fit-content;
  cursor: pointer;

  [data-icon] {
    --gutter: 2px;
    --knob-size: var(--sizing-4);

    position: relative;
    width: var(--sizing-8);
    height: var(--sizing-5);
    background-color: var(--color-bd-neutral);
    border-radius: var(--knob-size);
    color: var(--color-bg);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    flex-shrink: 0;
  }

  [data-icon]::after {
    content: "";
    height: var(--knob-size);
    width: var(--knob-size);
    transition: all 0.2s ease;
    position: absolute;
    left: var(--gutter);
    border-radius: var(--knob-size);
    background-color: currentColor;
  }

  &[data-hovered]:not([data-disabled]) [data-icon] {
    background-color: var(--color-bd-neutral-hover);
  }

  /**
  * ----------------------------------------------------------------------------
  * LABEL POSITION
  *-----------------------------------------------------------------------------
  */
  &[data-label-position="end"] {
    margin-inline-end: auto;
  }

  &[data-label-position="end"] [data-icon] {
    margin-inline-end: var(--inner-spacing-2);
  }

  &[data-label-position="start"] {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &[data-label-position="start"] [data-icon] {
    margin-inline-start: var(--inner-spacing-2);
  }

  /**
 * ----------------------------------------------------------------------------
 * CHECKED - BUT NOT DISABLED
 *-----------------------------------------------------------------------------
 */
  &[data-selected="true"] [data-icon] {
    background-color: var(--color-bg-accent);
    color: var(--color-bg);
  }

  &[data-hovered][data-selected="true"]:not([data-disabled]) [data-icon] {
    background-color: var(--color-bg-accent-hover);
    color: var(--color-bg);
  }

  &[data-selected="true"] [data-icon]::after {
    left: calc(100% - var(--knob-size) - var(--gutter));
  }

  /**
  * ----------------------------------------------------------------------------
  * FOCUS
  *-----------------------------------------------------------------------------
  */
  &[data-focus-visible] [data-icon] {
    box-shadow:
      0 0 0 2px var(--color-bg),
      0 0 0 4px var(--color-bd-focus);
  }

  /**
  * ----------------------------------------------------------------------------
  * DISABLED
  *-----------------------------------------------------------------------------
  */
  &[data-disabled] {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
  }
}
