.tooltip {
  background-color: var(--color-bg-assistive);
  color: var(--color-fg-on-assistive);
  padding: var(--inner-spacing-2) var(--inner-spacing-3);
  border-radius: var(--border-radius-elevation-3);
  z-index: var(--z-index-99);

  & [data-tooltip-trigger-arrow] {
    fill: var(--color-bg-assistive);
  }

  &[data-is-rounded]:is([data-tooltip-placement^="left"])
    [data-tooltip-trigger-arrow] {
    margin-left: -2px;
  }

  &[data-is-rounded]:is([data-tooltip-placement^="right"])
    [data-tooltip-trigger-arrow] {
    margin-right: -2px;
  }
}
