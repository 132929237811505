/* Note: these styles will shared across radio, Radio and toggle components */
.inline-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: fit-content;

  & span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  /**
  * ----------------------------------------------------------------------------
  * DISABLED
  *-----------------------------------------------------------------------------
  */
  &[data-disabled]:not([data-field-type="field-group"] &) {
    opacity: var(--opacity-disabled);
    cursor: default;
  }
}
