.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textField {
  display: flex;
  position: relative;
  padding: 0;
  height: var(--sizing-9);
  border: none;
  align-items: center;
  border-radius: var(--border-radius-elevation-3);
  background-color: var(--color-bg-neutral-subtle);
  max-inline-size: 100%;
  padding-inline-start: var(--inner-spacing-2);
  padding-inline-end: calc(var(--inner-spacing-3) + var(--icon-size-4));
  padding-block: var(--inner-spacing-3);
  box-shadow: inset 0 0 0 var(--border-width-1)
    var(--color-bd-on-neutral-subtle);
  cursor: pointer;
}

.formField[data-invalid] .textField {
  box-shadow: 0 0 0 var(--border-width-1) var(--color-bd-negative);
}

.formField[data-size="small"] .textField {
  padding-block: var(--inner-spacing-2);
}

.textField[data-focus-visible] {
  box-shadow:
    0 0 0 2px var(--color-bg),
    0 0 0 4px var(--color-bd-focus);
}

.textField[data-hovered] {
  background-color: var(--color-bg-neutral-subtle-hover);
  box-shadow: inset 0 0 0 var(--border-width-1)
    var(--color-bd-on-neutral-subtle-hover);
}

.textField [data-icon] {
  position: absolute;
  right: var(--inner-spacing-1);
}

.fieldValue {
  text-align: left;
  flex: 1;
}

.fieldValue[data-placeholder] {
  color: var(--color-fg-neutral-subtle);
}

.fieldValue [data-icon] {
  display: none;
}
