.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-elevation-3);
  background-color: var(--color-bg-neutral-subtle);
  flex: 1;
  max-inline-size: 100%;
  isolation: isolate;
  box-shadow: inset 0 0 0 1px var(--color-bd-on-neutral-subtle);
  padding-inline-end: var(--inner-spacing-2);
}

.input {
  border: 0;
  background-color: transparent;
  font-family: inherit;
  flex-grow: 1;
  color: var(--color-fg);
  text-overflow: ellipsis;
  max-inline-size: 100%;
  width: 100%;
  box-sizing: content-box;
  padding-block: var(--inner-spacing-1);
  padding-inline: var(--inner-spacing-2);
}

.inputWrapper:has([data-hovered]) {
  background-color: var(--color-bg-neutral-subtle-hover);
  box-shadow: inset 0 0 0 1px var(--color-bd-on-neutral-subtle-hover);
}

.inputWrapper:has([data-focused]) {
  background-color: transparent;
  box-shadow: none;
}

.inputWrapper:has([data-focused]):before {
  content: "";
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: 0 0 0 2px var(--color-bd-focus);
  border-radius: var(--border-radius-elevation-3);
  z-index: -1;
}

.inputWrapper:has([data-invalid]) {
  box-shadow: 0 0 0 1px var(--color-bd-negative);
}

.inputWrapper:has([data-invalid][data-hovered]) {
  box-shadow: 0 0 0 1px var(--color-bd-negative-hover);
}

.formField[data-size="small"] .input {
  block-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end)
  );
  padding-block: var(--inner-spacing-2);
}

.formField[data-size="small"] .inputWrapper {
  padding-inline-end: var(--inner-spacing-1);
}

.formField .inputWrapper [data-button] {
  border-radius: calc(
    var(--border-radius-elevation-3) - var(--inner-spacing-1)
  );
}

.formField[data-size="small"] .inputWrapper {
  border-radius: calc(
    var(--border-radius-elevation-3) - var(--inner-spacing-2)
  );
}
