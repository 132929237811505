@import "../../../shared/colors/colors.module.css";

.text {
  /* using auto instead of 100% so that text does not push everything to the edge when the parent is flex layout ( this happens in case of switch ) */
  width: auto;
  color: inherit;

  @each $color in colors {
    &[data-color="$(color)"] {
      color: var(--color-fg-$(color));
    }
  }
}

.clampedText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
