.link {
  position: relative;
  text-decoration: underline;
  text-underline-offset: 2px;
  color: currentColor;
  text-decoration-color: var(--color-bd-accent);
  text-decoration-thickness: 0.5px;

  &[data-hovered] {
    text-decoration: none;
  }

  &[data-focus-visible] {
    border-radius: calc(var(--border-radius-elevation-3) / 4);
    background: var(--color-bg);
    box-shadow:
      0 0 0 4px var(--color-bg),
      0 0 0 6px var(--color-bd-focus);
    text-decoration: none;
  }
}
